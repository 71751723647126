import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 707.000000 686.000000n">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,686.000000) scale(0.100000,-0.100000)">
		<path d="M3362 6319 c-138 -15 -332 -61 -419 -100 -173 -77 -315 -155 -384
-209 -24 -19 -45 -38 -47 -42 -2 -5 -8 -8 -13 -8 -6 0 -24 -13 -42 -30 -18
-16 -36 -30 -40 -30 -5 0 -5 -4 -2 -10 3 -5 1 -10 -5 -10 -12 0 -93 -79 -129
-125 -12 -16 -38 -47 -57 -67 -19 -21 -32 -38 -27 -38 4 0 0 -6 -9 -12 -19
-15 -135 -203 -165 -268 -64 -139 -103 -266 -139 -455 -25 -136 -23 -427 6
-560 11 -55 23 -115 27 -132 3 -18 10 -33 15 -33 6 0 7 -11 4 -24 -4 -15 0
-28 9 -36 8 -7 15 -20 15 -30 0 -24 67 -185 97 -232 13 -21 23 -40 23 -43 0
-8 44 -69 53 -73 4 -2 7 -8 7 -14 0 -9 89 -137 114 -164 54 -57 146 -152 146
-149 0 2 20 -18 45 -43 25 -25 45 -43 45 -39 0 3 17 -10 38 -28 20 -19 42 -35
48 -35 6 0 17 -6 23 -14 12 -14 134 -88 216 -129 192 -99 487 -167 716 -167
143 0 414 46 504 85 17 8 43 17 59 21 16 4 69 27 118 50 49 24 93 44 98 44 6
0 10 5 10 10 0 6 5 10 11 10 13 0 156 93 214 140 193 153 345 337 463 560 136
257 213 681 172 945 -27 176 -53 283 -105 425 -16 45 -84 186 -108 225 -109
175 -231 323 -353 427 -91 77 -99 83 -149 118 -48 33 -213 130 -222 130 -3 0
-34 13 -69 30 -150 69 -315 111 -509 130 -124 11 -184 11 -303 -1z m373 -40
c224 -27 499 -124 667 -236 144 -97 179 -124 273 -218 117 -115 146 -150 219
-260 105 -158 179 -324 222 -494 149 -598 -18 -1194 -452 -1612 -228 -219
-492 -355 -824 -426 -67 -14 -131 -18 -300 -18 -238 0 -327 12 -500 67 -162
53 -387 167 -474 243 -16 14 -33 25 -38 25 -11 0 -278 267 -278 278 0 5 -11
22 -25 38 -108 124 -247 432 -289 635 -129 636 108 1278 614 1659 113 85 285
182 400 225 75 28 298 84 374 93 102 13 306 14 411 1z"/>
<path d="M3324 6195 c-392 -60 -719 -244 -978 -550 -392 -462 -473 -1143 -200
-1685 224 -445 623 -750 1109 -846 127 -26 434 -26 558 -1 628 127 1100 589
1238 1211 89 402 1 855 -232 1196 -235 344 -566 566 -977 656 -147 32 -379 41
-518 19z m109 -73 c-4 -17 1 -18 54 -15 32 1 51 0 43 -3 -8 -4 -22 -13 -31
-21 -9 -8 -30 -18 -45 -22 -18 -5 21 -8 101 -8 72 0 118 2 103 4 -32 5 -38 23
-9 23 11 0 27 3 36 6 25 10 -2 24 -47 24 -22 0 -38 5 -38 12 0 13 277 4 345
-12 22 -5 64 -14 92 -21 29 -6 50 -15 47 -20 -3 -5 1 -9 9 -9 19 0 78 -30 101
-52 11 -10 23 -18 26 -18 23 0 240 -191 240 -211 0 -16 73 -81 105 -95 18 -7
40 -26 50 -41 10 -15 38 -47 62 -71 24 -24 43 -48 43 -52 0 -5 6 -13 13 -17 7
-4 22 -27 34 -50 11 -23 23 -40 27 -36 3 3 6 -2 6 -11 0 -10 9 -31 20 -46 11
-16 23 -43 27 -62 3 -18 9 -35 13 -38 14 -10 40 -92 40 -124 0 -19 6 -36 14
-39 15 -6 66 -127 66 -158 0 -11 5 -29 11 -40 18 -34 46 -260 42 -339 -5 -92
-41 -301 -54 -314 -5 -6 -9 -17 -9 -25 -1 -24 -76 -172 -83 -164 -4 3 -7 -3
-7 -15 0 -11 -4 -23 -9 -26 -5 -4 -19 -27 -31 -52 -13 -25 -31 -51 -41 -57
-11 -7 -19 -17 -19 -24 0 -6 -9 -14 -19 -18 -12 -4 -18 -12 -14 -21 3 -8 -1
-14 -9 -14 -7 0 -21 -18 -31 -40 -9 -22 -26 -48 -37 -57 -11 -10 -20 -25 -20
-34 0 -15 -19 -39 -106 -131 -48 -51 -80 -78 -129 -108 -19 -12 -35 -25 -35
-29 0 -10 -71 -43 -78 -36 -3 3 -16 -5 -29 -17 -21 -19 -26 -20 -34 -8 -8 11
-9 10 -4 -7 5 -17 2 -23 -8 -23 -9 0 -17 -4 -19 -8 -7 -20 -108 -83 -120 -76
-7 4 -10 4 -6 -1 12 -12 -24 -33 -105 -62 -66 -24 -83 -26 -217 -24 -85 1
-157 -2 -174 -9 -38 -14 -271 4 -301 24 -40 27 -85 78 -78 89 3 7 3 9 -1 5 -4
-3 -26 0 -48 8 -23 8 -44 12 -47 9 -3 -3 5 -8 17 -12 12 -3 22 -9 22 -14 0 -5
-7 -6 -15 -3 -8 4 -15 1 -15 -5 0 -6 7 -11 15 -11 8 0 15 -4 15 -10 0 -13
-101 -13 -132 -1 -13 5 -57 17 -98 26 -81 18 -174 64 -260 128 -66 49 -176
161 -196 200 -8 15 -19 27 -24 27 -5 0 -17 15 -26 33 -9 17 -20 34 -24 37 -4
3 -14 19 -23 36 -9 17 -30 45 -46 63 -19 20 -24 31 -14 27 14 -6 12 -2 -5 17
-12 13 -22 27 -22 31 0 4 -26 51 -59 105 -32 54 -62 113 -66 132 -4 19 -15 48
-24 64 -66 115 -104 465 -71 650 6 33 12 71 13 85 2 14 9 35 15 47 7 12 12 37
12 55 0 18 7 42 15 52 8 11 15 27 15 35 0 35 21 91 32 85 7 -4 8 -2 4 5 -8 12
25 111 50 149 8 12 12 27 9 31 -3 5 -2 11 3 13 4 1 26 41 50 88 55 111 167
271 217 312 22 18 43 37 47 43 4 5 12 10 17 10 5 0 21 10 34 22 14 12 28 19
31 16 2 -3 18 4 34 16 17 12 36 21 44 21 7 1 31 9 52 19 22 11 46 17 53 14 7
-3 20 0 28 7 9 8 25 9 42 5 16 -4 22 -10 16 -12 -19 -7 -16 -33 5 -34 13 0 14
-2 2 -6 -13 -4 -74 -42 -105 -65 -126 -93 -168 -127 -236 -194 -88 -85 -174
-187 -207 -244 -11 -19 -41 -71 -66 -114 -25 -44 -48 -85 -50 -90 -3 -7 -8 -5
-15 4 -5 8 -13 12 -16 9 -9 -9 6 -40 16 -33 12 7 11 4 -3 -36 -11 -33 -11 -33
-20 -10 -5 12 -6 32 -2 43 4 12 2 17 -5 13 -11 -7 -31 -69 -31 -98 0 -10 5
-18 11 -18 6 0 8 9 4 20 -3 11 -2 27 3 36 7 13 10 10 15 -16 7 -33 -6 -70 -25
-70 -7 0 -8 -14 -4 -41 4 -25 0 -71 -10 -117 -33 -153 -10 -467 48 -656 12
-39 21 -73 20 -76 0 -3 5 -12 12 -20 14 -17 59 -125 56 -136 -1 -4 3 -10 8
-13 5 -3 38 -48 72 -100 180 -269 501 -494 821 -575 49 -12 87 -24 85 -27 -5
-5 27 -6 244 -10 193 -3 294 11 459 67 136 46 307 128 341 164 3 3 25 18 50
34 25 16 103 87 173 158 71 70 132 126 135 124 4 -2 13 3 21 11 12 13 11 14
-8 8 -20 -6 -18 -1 13 47 108 161 193 411 217 633 23 216 -15 504 -87 662 -8
17 -14 38 -14 46 0 9 -4 18 -9 22 -6 3 -16 18 -24 33 -26 53 -79 144 -92 160
-98 122 -131 159 -205 232 -79 78 -214 180 -238 180 -6 0 -12 3 -14 8 -12 28
-338 145 -481 173 -174 34 -403 21 -595 -32 -41 -11 -76 -18 -80 -15 -3 3 2 6
11 6 10 0 17 5 17 10 0 6 8 10 18 10 14 0 15 2 2 10 -12 7 -11 10 5 16 11 5
29 16 40 27 42 39 92 62 160 73 101 17 104 17 98 -4z m14 -444 c-4 -113 -10
-144 -33 -207 -15 -40 -42 -89 -59 -107 -18 -19 -30 -36 -28 -38 3 -3 2 -10
-2 -16 -6 -9 -10 -9 -17 1 -7 10 -8 8 -3 -7 4 -17 -9 -35 -65 -88 -92 -86
-177 -128 -348 -172 -140 -35 -217 -60 -239 -77 -7 -5 -13 -6 -13 -1 0 4 -11
-3 -24 -16 -14 -12 -32 -20 -40 -17 -10 4 -13 1 -9 -9 3 -8 -1 -14 -10 -14
-22 0 -159 -150 -191 -209 -32 -60 -76 -200 -77 -250 l-1 -36 -8 30 c-14 55
-10 134 11 233 22 99 37 136 89 219 25 39 30 43 30 23 0 -29 12 -19 15 14 4
33 46 84 105 126 l49 35 3 100 3 100 263 3 262 2 53 34 c151 96 272 303 277
469 0 4 3 7 6 7 4 0 4 -60 1 -132z m64 -259 c-1 -141 -35 -263 -97 -346 -13
-17 -24 -36 -24 -40 0 -5 -27 -36 -59 -70 -66 -67 -133 -109 -231 -144 -36
-12 -69 -26 -75 -30 -10 -7 -43 -14 -110 -23 -29 -4 -34 -3 -20 4 17 7 18 9 3
9 -22 1 -24 17 -3 25 17 6 20 51 5 61 -6 4 -8 10 -6 14 3 4 1 13 -4 21 -9 15
-25 -33 -33 -105 -5 -39 -27 -65 -56 -65 -25 0 -130 -58 -193 -106 -141 -109
-238 -269 -255 -423 -7 -62 -23 -32 -23 45 0 90 35 238 75 317 41 81 69 119
80 109 5 -4 5 1 2 11 -4 9 -3 17 1 17 4 0 29 21 55 47 40 39 52 45 66 36 14
-9 20 -7 31 11 12 19 12 20 -4 7 -9 -8 -19 -11 -23 -7 -20 20 168 109 308 145
137 35 220 71 320 139 103 70 131 100 182 200 39 76 50 109 73 221 9 45 15 13
15 -80z m869 -149 c100 -28 194 -79 255 -138 67 -64 85 -88 85 -112 0 -11 4
-20 10 -20 16 0 50 -76 71 -158 26 -101 28 -295 3 -402 -27 -119 -76 -212
-153 -288 -37 -37 -74 -72 -82 -78 -25 -18 -146 -74 -161 -74 -8 0 -18 -4 -24
-9 -16 -15 -64 -23 -62 -10 2 6 -3 14 -11 17 -10 3 -12 0 -8 -11 10 -26 -65
-36 -298 -37 -121 -1 -245 -1 -275 -1 -51 2 -53 3 -31 15 14 8 19 15 12 17 -7
3 -13 11 -15 19 -2 8 -6 22 -10 30 -6 15 -3 162 4 185 2 6 0 18 -5 28 -4 10
-3 23 2 28 7 7 7 25 2 49 -7 33 -5 222 3 340 2 19 -1 43 -5 53 -5 11 -4 24 2
31 6 7 9 15 6 17 -8 8 -14 109 -7 113 6 4 6 157 1 183 -1 4 1 16 6 26 4 9 3
23 -2 30 -17 22 -15 77 3 84 12 4 13 8 3 14 -7 4 -13 22 -13 41 -1 24 4 35 19
40 11 4 151 6 310 3 257 -3 298 -6 365 -25z m-796 -202 c-6 -172 -18 -232 -60
-300 -29 -46 -81 -86 -123 -94 -34 -7 -53 -24 -26 -24 26 0 119 -47 143 -72
18 -20 20 -29 12 -63 -10 -43 -5 -58 11 -35 5 8 7 25 4 38 -3 12 -2 22 2 22
16 0 33 -56 33 -106 0 -29 5 -56 12 -60 6 -4 8 -3 5 2 -3 6 -1 16 4 24 7 11 9
-11 6 -70 -6 -108 -32 -168 -106 -245 -54 -57 -119 -92 -212 -116 -69 -18
-106 -19 -390 -17 l-314 3 -2 137 c-1 76 -4 142 -8 148 -11 17 -38 -13 -70
-77 -24 -47 -30 -53 -37 -37 -6 15 -7 13 -3 -7 2 -14 -4 -49 -15 -77 -11 -29
-20 -72 -20 -97 0 -24 -4 -47 -10 -50 -13 -8 -7 178 8 259 6 32 30 97 53 144
34 68 60 103 123 167 87 86 150 125 281 173 86 31 171 51 145 33 -12 -9 -11
-11 7 -11 15 0 21 5 17 14 -3 8 4 16 18 20 34 9 46 7 51 -6 2 -7 3 -5 2 5 -2
9 2 17 8 17 16 0 122 56 174 91 75 51 166 153 199 220 32 65 64 163 64 192 0
10 4 17 10 17 6 0 8 -59 4 -162z m-1407 -70 c-3 -8 -6 -5 -6 6 -1 11 2 17 5
13 3 -3 4 -12 1 -19z m1420 -570 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2
-19 0 -25z m-417 -1178 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10
2 0 4 -4 4 -10z"/>
<path d="M4350 5816 c0 -2 8 -10 18 -17 15 -13 16 -12 3 4 -13 16 -21 21 -21
13z"/>
<path d="M2236 3984 c-3 -9 -2 -23 3 -32 8 -13 10 -10 9 16 -1 36 -3 39 -12
16z"/>
<path d="M2328 3892 c9 -10 19 -17 21 -16 6 5 -20 34 -30 34 -5 0 -1 -8 9 -18z"/>
<path d="M4716 3853 c-6 -14 -5 -15 5 -6 7 7 10 15 7 18 -3 3 -9 -2 -12 -12z"/>
<path d="M4650 3775 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"/>
<path d="M4265 3433 c-18 -14 -18 -15 -2 -9 10 3 23 6 28 6 5 0 9 5 9 10 0 14
-12 12 -35 -7z"/>
<path d="M3643 3233 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
<path d="M3276 5285 c-9 -26 -7 -32 5 -12 6 10 9 21 6 23 -2 3 -7 -2 -11 -11z"/>
<path d="M2547 5053 c-2 -5 -4 -24 -5 -43 -2 -35 -2 -35 11 -10 7 14 9 27 5
28 -4 2 -8 8 -8 13 0 6 4 8 9 4 5 -3 12 -1 16 5 3 5 -1 10 -9 10 -8 0 -17 -3
-19 -7z"/>
<path d="M2442 4954 c-7 -8 -8 -14 -3 -14 10 0 25 19 20 25 -2 1 -10 -3 -17
-11z"/>
<path d="M2527 4934 c3 -7 9 -14 14 -14 14 0 10 17 -5 23 -10 4 -13 1 -9 -9z"/>
<path d="M2365 4847 c-15 -24 -18 -80 -3 -71 5 3 6 9 3 14 -3 5 1 25 9 45 17
40 13 45 -9 12z"/>
<path d="M2427 4832 c-24 -27 -21 -36 4 -11 11 11 19 23 16 25 -2 2 -11 -4
-20 -14z"/>
<path d="M2765 4870 c-22 -11 -29 -17 -15 -14 29 8 80 33 65 33 -5 0 -28 -8
-50 -19z"/>
<path d="M2783 4825 c-1 -19 3 -38 8 -41 5 -3 9 13 9 35 0 50 -16 55 -17 6z"/>
<path d="M2730 4810 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"/>
<path d="M2665 4790 c-3 -6 1 -7 9 -4 18 7 21 14 7 14 -6 0 -13 -4 -16 -10z"/>
<path d="M2720 4740 c0 -12 8 -12 35 0 18 8 17 9 -7 9 -16 1 -28 -3 -28 -9z"/>
<path d="M2586 4703 c-6 -14 -5 -15 5 -6 7 7 10 15 7 18 -3 3 -9 -2 -12 -12z"/>
<path d="M4170 5270 c20 -13 40 -13 40 0 0 6 -12 10 -27 10 -22 0 -25 -2 -13
-10z"/>
<path d="M4088 5263 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M4114 5249 c-8 -14 18 -31 30 -19 9 9 0 30 -14 30 -5 0 -12 -5 -16
-11z"/>
<path d="M4522 5181 c13 -21 48 -29 48 -11 0 6 -3 9 -7 8 -5 -1 -18 3 -31 10
-22 13 -22 12 -10 -7z"/>
<path d="M4068 5144 c-4 -5 3 -25 15 -44 11 -18 23 -28 25 -21 2 7 -3 20 -11
29 -8 9 -16 24 -19 32 -2 8 -7 10 -10 4z"/>
<path d="M4570 5141 c0 -6 6 -11 13 -11 23 0 60 -48 61 -78 1 -16 -6 -36 -15
-46 -15 -15 -17 -13 -22 36 -5 38 -13 57 -29 70 -16 11 -24 13 -27 6 -2 -7 1
-14 8 -16 6 -2 8 -8 5 -14 -6 -10 13 -95 26 -113 4 -5 10 -32 14 -58 3 -27 11
-54 18 -61 11 -10 13 2 13 62 0 80 13 102 32 55 l12 -28 -2 35 c0 19 2 41 7
48 7 11 1 16 -17 13 -5 -1 -6 3 -3 8 3 5 -2 23 -11 40 -15 26 -29 36 -75 59
-5 2 -8 -1 -8 -7z"/>
<path d="M4012 4995 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z"/>
<path d="M4395 5000 c4 -14 10 -41 15 -60 10 -48 20 -70 31 -70 12 0 12 3 -6
64 -16 56 -53 117 -40 66z"/>
<path d="M4039 5004 c0 -5 0 -178 0 -384 l1 -375 92 1 c186 1 276 80 307 269
12 75 20 290 10 273 -3 -5 -12 11 -19 37 -34 121 -114 172 -285 182 -60 3
-105 2 -106 -3z"/>
<path d="M3940 4977 c0 -9 32 -67 40 -72 18 -11 22 36 4 56 -14 16 -44 27 -44
16z"/>
<path d="M4462 4930 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M4430 4851 c0 -6 5 -13 10 -16 6 -3 10 1 10 9 0 9 -4 16 -10 16 -5 0
-10 -4 -10 -9z"/>
<path d="M3870 4796 c0 -9 7 -16 16 -16 9 0 14 5 12 12 -6 18 -28 21 -28 4z"/>
<path d="M3840 4710 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M3978 4688 c-15 -11 -16 -20 -7 -57 15 -66 28 -84 30 -41 1 32 2 33
9 10 7 -20 8 -16 9 23 1 26 -3 47 -8 47 -5 0 -11 7 -13 16 -2 12 -6 13 -20 2z"/>
<path d="M3778 4632 c-25 -5 -22 -25 6 -59 13 -15 25 -37 27 -47 6 -30 26 -30
32 1 4 20 0 31 -14 41 -10 7 -19 21 -19 30 0 25 -13 38 -32 34z"/>
<path d="M3940 4610 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M3900 4583 c0 -10 5 -25 10 -33 7 -11 10 -7 10 18 0 17 -4 32 -10 32
-5 0 -10 -8 -10 -17z"/>
<path d="M3715 4440 c0 -22 3 -40 7 -40 5 0 8 14 8 30 0 17 0 33 1 37 0 4 -3
9 -8 10 -5 2 -8 -15 -8 -37z"/>
<path d="M3821 4373 c-16 -39 -10 -68 8 -35 7 13 11 33 9 45 -3 19 -5 18 -17
-10z"/>
<path d="M3851 4354 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M3730 4349 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M3907 4315 c-6 -35 -6 -37 9 -21 6 6 8 20 4 31 -6 16 -8 15 -13 -10z"/>
<path d="M3975 4275 c-10 -25 -15 -49 -11 -52 11 -11 26 18 26 49 0 22 4 26
18 22 10 -4 13 -2 7 3 -5 4 -13 12 -16 16 -4 5 -15 -12 -24 -38z"/>
<path d="M3940 4285 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"/>
<path d="M3996 4239 c-4 -23 -4 -44 -1 -47 3 -3 5 0 5 7 0 7 4 10 9 7 5 -3 9
12 9 34 1 52 -14 51 -22 -1z"/>
<path d="M3800 4238 c0 -19 2 -20 10 -8 13 19 13 30 0 30 -5 0 -10 -10 -10
-22z"/>
<path d="M3932 4240 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M4090 4211 c-12 -23 -22 -91 -13 -91 3 0 17 12 30 26 13 14 25 25 27
23 1 -2 10 -15 19 -29 12 -17 17 -19 17 -9 0 28 -22 79 -31 73 -5 -3 -9 2 -9
10 0 22 -27 20 -40 -3z"/>
<path d="M4226 4137 c-19 -89 -19 -86 -10 -89 13 -6 35 82 25 99 -8 15 -10 14
-15 -10z"/>
<path d="M4120 4130 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"/>
<path d="M3851 4106 c-16 -19 -11 -29 8 -17 7 5 12 14 9 21 -3 9 -8 7 -17 -4z"/>
<path d="M3890 4095 c-21 -22 -22 -29 -12 -45 7 -11 12 -27 12 -35 0 -8 6 -15
13 -15 35 0 48 54 24 98 l-13 23 -24 -26z m27 -57 c-3 -8 -6 -5 -6 6 -1 11 2
17 5 13 3 -3 4 -12 1 -19z"/>
<path d="M4327 4043 c-4 -3 -7 -11 -7 -17 0 -6 5 -5 12 2 6 6 9 14 7 17 -3 3
-9 2 -12 -2z"/>
<path d="M3465 4920 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M3480 4890 c-18 -16 -31 -33 -27 -36 3 -4 20 8 38 25 17 18 29 34 27
36 -3 3 -20 -9 -38 -25z"/>
<path d="M3257 4620 c-7 -51 -3 -60 12 -32 6 11 7 31 2 48 -7 28 -8 27 -14
-16z"/>
<path d="M3021 4593 c-1 -31 -5 -61 -8 -66 -3 -6 -1 -7 5 -3 7 4 12 22 12 41
0 19 4 45 10 59 7 20 6 26 -4 26 -8 0 -13 -18 -15 -57z"/>
<path d="M3326 4604 c-11 -30 -7 -64 9 -64 20 0 29 23 21 55 -7 28 -21 33 -30
9z"/>
<path d="M2837 4603 c-4 -3 -7 -11 -7 -17 0 -6 5 -5 12 2 6 6 9 14 7 17 -3 3
-9 2 -12 -2z"/>
<path d="M3372 4555 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z"/>
<path d="M3401 4554 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M3460 4530 c0 -19 3 -21 12 -12 9 9 9 15 0 24 -9 9 -12 7 -12 -12z"/>
<path d="M2977 4492 l-48 -16 3 -125 3 -125 50 0 c137 -1 172 4 206 27 18 12
39 32 45 45 8 16 13 19 22 10 9 -9 12 -8 12 4 0 9 -4 20 -10 23 -5 3 -10 18
-10 32 0 45 -38 110 -74 127 -43 20 -135 19 -199 -2z"/>
<path d="M3530 4373 c0 -10 4 -14 8 -8 4 6 11 13 16 18 5 4 2 7 -7 7 -10 0
-17 -8 -17 -17z"/>
<path d="M3450 4368 c0 -19 18 -43 25 -36 9 9 -4 48 -16 48 -5 0 -9 -6 -9 -12z"/>
<path d="M3401 4314 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M3337 4299 c7 -7 15 -10 18 -7 3 3 -2 9 -12 12 -14 6 -15 5 -6 -5z"/>
<path d="M3254 4266 c1 -15 8 -32 15 -39 10 -9 11 -6 5 18 -9 40 -24 55 -20
21z"/>
<path d="M3225 4260 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M2842 4185 c0 -22 3 -49 8 -60 7 -17 9 -15 8 10 0 17 -4 44 -8 60 -7
29 -8 28 -8 -10z"/>
<path d="M3026 4203 c-3 -3 -2 -11 3 -17 5 -6 11 -24 14 -41 3 -24 4 -22 6 12
1 40 -9 60 -23 46z"/>
<path d="M2671 4105 c0 -22 4 -47 9 -55 8 -12 9 -10 9 10 -1 14 -5 39 -9 55
-7 29 -8 29 -9 -10z"/>
<path d="M2767 4095 c-2 -14 -1 -40 3 -58 7 -27 8 -24 9 26 1 61 -4 73 -12 32z"/>
<path d="M2601 4056 c-1 -37 2 -47 10 -39 8 8 8 22 1 50 l-10 38 -1 -49z"/>
<path d="M2630 4084 c0 -10 5 -26 10 -34 8 -11 9 -7 4 15 -8 37 -14 45 -14 19z"/>
<path d="M2431 4054 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M2645 4000 c3 -5 10 -10 16 -10 5 0 9 5 9 10 0 6 -7 10 -16 10 -8 0
-12 -4 -9 -10z"/>
<path d="M2686 3995 c4 -8 10 -12 15 -9 14 8 10 24 -6 24 -9 0 -12 -6 -9 -15z"/>
<path d="M2608 3973 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M2477 2806 c-90 -33 -162 -107 -186 -191 -17 -63 -13 -158 9 -218 24
-63 96 -147 200 -232 39 -32 78 -71 86 -87 20 -38 10 -82 -24 -109 -35 -28
-106 -23 -184 13 -31 15 -58 22 -60 17 -2 -5 -2 -60 0 -122 l3 -113 52 -20
c75 -27 215 -26 279 3 72 33 144 105 168 169 27 73 26 166 -1 240 -22 57 -84
127 -237 264 -57 51 -62 59 -62 96 0 80 61 103 170 63 30 -11 63 -23 73 -26
15 -5 17 5 17 110 l0 116 -66 21 c-76 23 -180 26 -237 6z"/>
<path d="M3167 2806 c-95 -35 -165 -113 -187 -208 -17 -67 -7 -167 22 -226 24
-48 106 -136 216 -232 55 -47 64 -61 69 -97 7 -56 -27 -93 -85 -93 -40 0 -149
33 -166 50 -22 22 -26 5 -26 -113 l0 -124 53 -17 c80 -26 174 -31 238 -13 118
35 196 113 225 229 38 148 -36 291 -222 429 -93 69 -120 147 -66 189 22 18 36
21 78 17 28 -3 74 -17 103 -31 l51 -26 0 119 0 119 -42 17 c-57 24 -209 30
-261 11z"/>
<path d="M670 2269 l0 -520 208 3 c199 3 209 4 263 29 117 55 172 141 172 269
0 103 -38 173 -121 219 -14 8 -9 16 31 57 80 80 97 195 47 299 -26 53 -59 87
-116 119 -66 37 -129 46 -314 46 l-170 0 0 -521z m350 292 c33 -24 47 -79 30
-121 -16 -39 -43 -57 -97 -66 l-42 -6 -3 107 c-2 59 -1 109 2 112 10 10 86 -8
110 -26z m12 -409 c47 -28 63 -96 32 -141 -21 -29 -72 -51 -119 -51 l-35 0 0
111 0 111 48 -7 c26 -4 59 -14 74 -23z"/>
<path d="M1655 2778 c-2 -7 -15 -60 -30 -118 -15 -58 -73 -283 -131 -501 -57
-218 -104 -399 -104 -402 0 -4 53 -7 119 -7 l119 0 11 48 c7 26 16 64 22 85
l10 37 124 0 c106 0 125 -2 125 -16 0 -8 7 -46 16 -85 l16 -69 124 0 125 0 -7
23 c-4 12 -22 81 -41 152 -19 72 -65 249 -103 395 -38 146 -82 311 -97 368
l-27 102 -133 0 c-98 0 -135 -3 -138 -12z m188 -544 c13 -63 22 -116 19 -119
-3 -3 -36 -5 -74 -5 -81 0 -77 -13 -44 160 13 69 27 150 31 180 11 85 18 82
32 -14 7 -48 23 -140 36 -202z"/>
<path d="M3776 2778 c-2 -7 -16 -128 -30 -268 -15 -140 -37 -358 -51 -485 -14
-126 -25 -240 -25 -252 0 -22 3 -23 109 -23 l109 0 6 48 c3 26 15 164 26 307
28 361 27 356 60 205 15 -69 39 -186 55 -260 15 -74 33 -160 40 -190 7 -30 16
-67 19 -82 6 -28 7 -28 85 -28 54 0 80 4 83 13 3 6 33 157 69 335 58 291 79
373 79 295 0 -16 12 -166 27 -335 l27 -308 119 0 119 0 -6 38 c-6 37 -18 139
-76 667 -17 149 -32 285 -35 303 l-6 32 -152 0 -153 0 -23 -122 c-13 -68 -35
-195 -50 -283 l-27 -160 -13 80 c-14 92 -56 331 -73 423 l-12 62 -148 0 c-110
0 -150 -3 -152 -12z"/>
<path d="M5067 2783 c-3 -4 -12 -35 -20 -68 -9 -33 -36 -139 -62 -235 -87
-329 -185 -710 -185 -720 0 -6 46 -10 119 -10 l118 0 19 85 19 85 122 0 122 0
10 -37 c5 -21 13 -51 16 -68 15 -70 5 -65 136 -65 67 0 119 4 119 9 0 12 -68
278 -170 661 -29 107 -62 233 -74 280 l-22 85 -131 3 c-72 1 -134 -1 -136 -5z
m147 -353 c9 -58 26 -147 37 -197 27 -132 31 -123 -56 -123 -41 0 -75 2 -75 4
0 3 7 36 15 75 25 119 55 298 55 327 0 47 7 23 24 -86z"/>
<path d="M5720 2270 l0 -520 120 0 120 0 0 215 0 215 100 0 100 0 0 -215 0
-215 120 0 120 0 0 520 0 520 -120 0 -120 0 0 -190 0 -190 -100 0 -100 0 0
190 0 190 -120 0 -120 0 0 -520z"/>
<path d="M2070 1021 l0 -521 151 0 c211 0 293 23 390 108 101 90 145 237 136
456 -3 77 -12 152 -22 182 -39 125 -130 223 -246 264 -54 19 -91 23 -236 27
l-173 5 0 -521z m330 272 c42 -28 60 -54 74 -107 22 -77 28 -172 17 -257 -16
-124 -55 -179 -140 -198 l-41 -9 0 294 0 294 33 0 c18 0 44 -8 57 -17z"/>
<path d="M3066 1532 c-2 -4 -21 -71 -40 -148 -20 -76 -54 -206 -76 -289 -76
-285 -150 -571 -150 -583 0 -9 34 -12 119 -12 l118 0 19 85 19 85 122 0 122 0
10 -38 c6 -20 11 -41 11 -47 0 -5 5 -27 11 -48 l10 -37 119 0 c90 0 120 3 120
13 0 6 -18 80 -40 162 -45 171 -180 685 -207 790 l-19 70 -131 3 c-72 1 -134
-1 -137 -6z m179 -527 c14 -71 25 -133 25 -137 0 -5 -34 -8 -75 -8 -41 0 -75
3 -75 8 0 4 5 25 10 47 14 57 60 323 61 354 1 14 8 -10 16 -54 7 -44 25 -138
38 -210z"/>
<path d="M3600 1420 l0 -120 80 0 80 0 0 -400 0 -400 120 0 120 0 0 400 0 400
80 0 80 0 0 120 0 120 -280 0 -280 0 0 -120z"/>
<path d="M4447 1483 c-30 -108 -59 -218 -102 -383 -23 -91 -68 -261 -99 -377
-31 -117 -56 -215 -56 -218 0 -3 53 -5 119 -5 l119 0 11 48 c7 26 16 64 22 85
l10 37 122 0 122 0 19 -85 19 -85 124 0 c114 0 124 1 119 18 -16 55 -60 221
-101 382 -25 102 -53 205 -60 230 -8 25 -35 127 -61 228 l-48 182 -132 0 -131
0 -16 -57z m162 -305 c12 -85 48 -269 57 -300 5 -16 -3 -18 -72 -18 l-77 0 6
38 c3 20 17 99 31 175 14 76 26 149 26 163 0 54 17 19 29 -58z"/>
</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>
}
